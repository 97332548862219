import React from "react"
import TemplatePageScorePrice from "@components/pageScore/pagePrice/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div>
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Planes y precios de Beex Contact Center: Software para Centro de Llamadas con IA [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/contact-center/precios/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Descubre los detalles de todos los planes que ofrece Beex Contact Center y cuál es mejor para tu Centro de Contacto. "
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Planes y precios de Beex Contact Center: Software para Centro de Llamadas con IA"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/contact-center/precios/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/og-score.png`}
      />
      <meta
        property="og:description"
        content="Descubre los detalles de todos los planes que ofrece Beex Contact Center y cuál es mejor para tu Centro de Contacto."
      />
    </Helmet>
    <TemplatePageScorePrice location={location} />
  </div>
)

export default IndexPage
