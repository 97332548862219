import React from "react"
import TitleUnderline from "./titleUnderline"

const Question = ({ data }) => {
  return (
    <section className="container-questions-pages-price">
      <p className="container-questions-pages-price-title">
      <TitleUnderline underline="Preguntas" /> frecuentes
      </p>


      <section className="container-questions-pages-price-cards">
        {data.map(elemento => {
          return (
            <>
              <section key={elemento.title} className="container-questions-pages-price-cards-item">
                <p className="container-questions-pages-price-cards-item-title">{elemento.title}</p>
                <p className="container-questions-pages-price-cards-item-text">{elemento.textbody} </p>
              </section>
              {elemento.hr ? "" : <hr />}
            </>
          )
        })}
      </section>
    </section>
  )
}

export default Question
