import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg width="200" height="12" viewBox="0 0 200 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M51.6895 10.0658C45.1664 9.30907 5.09622 11.3155 2.23623 11.1672C-0.301636 10.9083 1.46812 5.03403 3.43746 4.11309C4.13568 3.78576 5.04771 3.69917 7.22852 3.78951C9.14121 3.86787 22.3185 3.41071 54.1977 2.39581C54.8229 2.56521 56.398 1.92784 56.7728 2.14115C57.0585 2.26486 61.689 2.21742 68.5722 2.10978C76.2958 1.98792 97.3213 1.57121 107.691 1.40709C122.995 1.15736 117.238 1.2014 134.759 0.991872C138.424 0.947101 135.563 1.31815 139.872 1.16365C149.062 0.839628 169.156 1.11248 176.111 0.820796C181.69 0.586374 182.905 0.573287 188.268 0.807243C192.558 0.993291 222.825 1.00652 226.241 0.659474C227.063 0.555867 227.689 0.608004 227.671 0.736374C228.028 0.74862 248.175 1.15006 248.264 1.25642C248.407 1.38528 249.015 1.4597 249.605 1.37772C250.678 1.23579 272.578 1.18466 272.881 1.56526C273.736 2.66225 270.022 7.15149 267.39 8.2212C264.634 9.34078 251.052 7.75309 239.394 8.29301C239.394 8.29301 236.104 8.25516 231.17 8.19001C182.991 7.59784 184.635 7.9099 175.445 8.23951C174.141 7.84472 169.222 8.79026 166.167 8.11658C164.898 7.83914 155.942 7.8121 153.938 8.08523C153.509 8.14536 150.327 8.15809 145.697 8.16086C141.084 8.16368 135.024 8.15097 128.891 8.18829C125.816 8.20692 122.723 8.21992 119.791 8.25015C116.842 8.27475 114.035 8.32769 111.549 8.38161C106.561 8.48939 102.842 8.62892 101.697 8.83764C92.5993 8.26297 60.3792 9.7012 51.6895 10.0658Z" fill="#FFBA00"/>
</svg>
  </span>
)

export default titleUnderline
